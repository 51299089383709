<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row>
                <v-overlay :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{fraseOverlay}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tableIncidencias"
                        :tableName="'Incidencias'"
                        :url="url"
                        :columns="columns"
                        @loadModalData="loadModalData"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                        @datosTabla="datosTabla"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" style="padding-right: 10px;" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template v-if="rol == 'admin-empresa' || rol == 'admin-sucursal'" slot="actionButtonsLeft">
                            <div style="padding-right: 10px;">
                                <v-speed-dial
                                    v-model="btnDoc"
                                    direction="bottom"
                                    open-on-hover
                                    transition="scale-transition"
                                    class="btn-download-cloud2"
                                >
                                    <template v-slot:activator>
                                        <v-btn
                                            v-model="btnDoc"
                                            color="#FFFFFF"
                                            fab
                                            class="btn-download-cloud2"
                                            elevation="3"
                                        >
                                            <v-icon color="#96999A" v-if="btnDoc">mdi-close</v-icon>
                                            <v-icon color="#96999A" v-text="'$get_app_outline'" v-else></v-icon>
                                        </v-btn>
                                    </template>
                                    

                                    <v-btn
                                        fab
                                        small
                                        color="#FFFFFF"
                                        @click="exportar('pdf')"
                                    >
                                        <v-icon color="red">mdi-file-pdf</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        small
                                        color="#FFFFFF"
                                        @click="exportar('xlsx')"
                                    >
                                        <v-icon color="green">mdi-file-excel</v-icon>
                                    </v-btn>
                                </v-speed-dial>
                            </div>
                        </template>
                        <template v-if="rol == 'root' || rol == 'admin'" slot="actionButtonsLeft">
                            <v-btn
                                color="#FFFFFF"
                                fab
                                class="btn-download-cloud2 mr-3"
                                elevation="0"
                                width="42"
                                height="42"
                                @click="abrirModalReportes()"
                            >
                                <v-icon color="#96999A" v-text="'$get_app_outline'"></v-icon>
                            </v-btn>
                        </template>

                        

                        <template slot="filters">
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="advancedSearch.fecha_inicio"
                                    label="Fecha inicial"
                                    placeholder="dd/mm/aaaa"
                                    ref="SearchFechaInicio"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="advancedSearch.fecha_final"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="SearchFechaFinal"
                                />
                            </v-col>
                            
                            <template v-if="rol == 'root'">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="empresa_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template
                                v-if="
                                    rol == 'root' ||
                                    rol == 'admin' ||
                                    rol == 'admin-empresa' ||
                                    rol == 'admin-sucursal'
                                "
                            >
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="departamentos"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Departamento"
                                        persistent-hint
                                        v-model="incidencia.departamento_id"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0" v-if="rol != 'empleado'">
                                <v-autocomplete
                                    outlined
                                    :items="empleados"
                                    item-text="nombre_completo"
                                    item-value="id"
                                    label="Empleados"
                                    persistent-hint
                                    v-model="advancedSearch.empleado_id"
                                    data-vv-name="Empleados"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="tiposIncidencias"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Tipo incidencia"
                                    persistent-hint
                                    v-model="advancedSearch.tipo_id"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="estatus"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Estatus"
                                    persistent-hint
                                    v-model="advancedSearch.estatus_id"
                                ></v-autocomplete>
                            </v-col>
                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td v-if="rol != 'empleado'" style="">
                                    <div>
                                        <v-list  color="transparent" class="py-0 my-0">
                                            <v-list-item class="py-0 my-0 px-0">
                                                <v-list-item-avatar  class="py-0 my-0">
                                                    <v-img  :src="urlAvatar+item.foto"></v-img>
                                                </v-list-item-avatar>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-list-item-content v-on="on" class="ml-0 py-0 my-0">
                                                            <v-list-item-title class="tblPrincipal">{{ item.nombre_completo }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                    <span>{{ item.nombre_completo }}</span>
                                                 </v-tooltip>  
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>

                                <td
                                    v-if="rol != 'empleado'"
                                    class="trIncidencias"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                class="vChip"
                                                :color="item.color"
                                                text-color="#000"
                                                v-on="on"
                                            >
                                                {{
                                                    item.nombre_tipo.length < 12
                                                    ? item.nombre_tipo
                                                    : item.nombre_tipo.substr(0, 11) + "..."
                                                }}
                                            </v-chip>
                                        </template>
                                        <div>
                                            <span class="font-weight-bold">{{ item.nombre_tipo }}</span>
                                            <v-divider color="#fff" class="mb-1"></v-divider>
                                            <span>{{ item.descripcion }}</span>
                                        </div>
                                    </v-tooltip>
                                </td>
                                <td
                                    v-else
                                    class="trIncidencias"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                class="vChip"
                                                :color="item.color"
                                                text-color="#000"
                                                v-on="on"
                                            >
                                                {{
                                                    item.nombre_tipo.length < 12
                                                    ? item.nombre_tipo
                                                    : item.nombre_tipo.substr(0, 11) + "..."
                                                }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.nombre_tipo }}</span>
                                    </v-tooltip>
                                </td>
                                <td >
                                    <div class="tblPrincipal">
                                        {{ dateFormat(item.fecha_inicio) }}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ dateFormat(item.fecha_final) }}
                                    </div>
                                </td>
                                <td v-if="rol == 'empleado'">
                                    {{ item.descripcion }}
                                </td>

                                <td>
                                    <div class="tblCenter">
                                        <template v-if="item.activo == false ">
                                            <div
                                                class="mx-0 px-0 tipoLetra estatus_cancelado"
                                            >
                                                <img
                                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                                    src="/static/icon/cancelado_incidencia.png"
                                                    alt="Empleado"
                                                />
                                                &nbsp;&nbsp;&nbsp; Cancelado
                                            </div>
                                        </template>


                                        <div
                                            v-else-if="rol=='empleado'"
                                            class="mx-0 px-0 tipoLetra"
                                            :class="getColorTextoStatus(item.valor_estatus)"
                                        >
                                            <img
                                                v-if="item.valor_estatus === 'estatus_aprobado'"
                                                style="height: 20px; width: 20px;  background-color: transparent;"
                                                src="/static/icon/aprovado.png"
                                                alt="Empleado"
                                            />
                                            <img
                                                v-else-if="item.valor_estatus === 'estatus_rechazado'"
                                                style="height: 20px; width: 20px;  background-color: transparent;"
                                                src="/static/icon/cancelado.png"
                                                alt="Empleado"
                                            />
                                            <img
                                                v-else-if="item.valor_estatus === 'estatus_pendiente'"
                                                style="height: 20px; width: 20px;  background-color: transparent;"
                                                src="/static/icon/pendiente.png"
                                                alt="Empleado"
                                            />
                                            <img
                                                v-else
                                                style="height: 20px; width: 20px;  background-color: transparent;"
                                                src="/static/icon/revision.png"
                                                alt="Empleado"
                                            />
                                            &nbsp;&nbsp;&nbsp; {{ item.nombre_estatus }}
                                        </div>

                                        <v-menu v-else buttom offset-y class="elevation-0 d-inline" content-class=" menuClassOpciones"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <div
                                                    class="mx-0 px-0 tipoLetra"
                                                    :class="getColorTextoStatus(item.valor_estatus)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <img
                                                        v-if="item.valor_estatus === 'estatus_aprobado'"
                                                        style="height: 20px; width: 20px;  background-color: transparent;"
                                                        src="/static/icon/aprovado.png"
                                                        alt="Empleado"
                                                    />
                                                    <img
                                                        v-else-if="item.valor_estatus === 'estatus_rechazado'"
                                                        style="height: 20px; width: 20px;  background-color: transparent;"
                                                        src="/static/icon/cancelado.png"
                                                        alt="Empleado"
                                                    />
                                                    <img
                                                        v-else-if="item.valor_estatus === 'estatus_pendiente'"
                                                        style="height: 20px; width: 20px;  background-color: transparent;"
                                                        src="/static/icon/pendiente.png"
                                                        alt="Empleado"
                                                    />
                                                    <img
                                                        v-else
                                                        style="height: 20px; width: 20px;  background-color: transparent;"
                                                        src="/static/icon/revision.png"
                                                        alt="Empleado"
                                                    />
                                                    &nbsp;&nbsp;&nbsp; {{ item.nombre_estatus }}
                                                </div>
                                            </template>

                                            <v-list>
                                                <template v-for="i_estatus in estatusTabla">
                                                    <v-list-item
                                                        v-if="item.valor_estatus != i_estatus.valor"
                                                        @click="()=>actualizarEstatus(item, i_estatus)"

                                                    >
                                                        <v-list-item-title>{{i_estatus.nombre}}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                <v-list-item
                                                    @click="()=>abrirModalCancelar(item)"
                                                >
                                                    <v-list-item-title>Cancelar</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>

                                    </div>
                                </td>

                                <template v-if="rol != 'empleado'">
                                    <td>
                                        <div class="tblOpciones">
                                            <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassOpciones"> 
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon 
                                                        fab
                                                        dark
                                                        small
                                                        class="elevation-0"
                                                    >
                                                        <template  v-for="(item,valor) in attrs" >
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                        </template>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <template v-if="item.activo == true">
                                                        <v-list-item
                                                            @click="abrirModal('update', item)"
                                                            v-if="(item.valor_estatus == 'estatus_aprobado' && item.valor_tipo == 'tipo_incidencia_incapacidad' && sistema == 'nominas') ? false:true"
                                                        >
                                                            <v-list-item-title>Editar</v-list-item-title>
                                                        </v-list-item>
                                                        <template v-if="permisos.delete">
                                                            <v-list-item
                                                                @click="abrirModalCancelar(item)"
                                                                v-if="(item.valor_estatus == 'estatus_aprobado' && item.valor_tipo == 'tipo_incidencia_incapacidad' && sistema == 'nominas') ? false:true"
                                                            >
                                                                <v-list-item-title>Cancelar</v-list-item-title>
                                                            </v-list-item>
                                                        </template>
                                                    </template>
                                                    <template v-if="item.activo == false">

                                                        <v-menu v-model="item.menu" left buttom :close-on-content-click="false" class="elevation-0 d-inline" content-class="mt-0 menuClassAccionesVer menu-width"> 
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-list-item
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-list-item-title>Ver motivo de cancelación</v-list-item-title>
                                                                </v-list-item>
                                                            </template>

                                                            <v-list>
                                                                <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                                                                    <v-btn
                                                                        @click="item.menu = false"
                                                                        small
                                                                        icon
                                                                        text
                                                                        :ripple="false" 
                                                                        class="v-btn-cerrar mr-2"
                                                                    >
                                                                        <v-icon class="icono-cerrar"></v-icon>
                                                                    </v-btn>
                                                                </div>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-list-item-subtitle>
                                                                            <v-row class="pa-0 ma-0">
                                                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                    <template v-if="item.eliminacion">
                                                                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 mb-4 mt-3">
                                                                                            <p class="mb-0 informacionCancelacion ">Información de cancelación:</p>
                                                                                        </v-col>
                                                                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                            <p class="mb-0 informacionGeneralSubtitle">Responsable:</p>
                                                                                            <p class="informacionGeneralText text-wrap">{{item.eliminacion.responsable}}</p>
                                                                                        </v-col>
                                                                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                            <p class="mb-0 informacionGeneralSubtitle">Fecha de cancelación:</p>
                                                                                            <p class="informacionGeneralText text-wrap">{{dateFormat(item.eliminacion.fecha, 'modal')}}</p>
                                                                                        </v-col>
                                                                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                            <p class="mb-0 informacionGeneralSubtitle">Motivo:</p>
                                                                                            <p class="informacionGeneralText text-wrap">{{item.eliminacion.motivo}}</p>
                                                                                        </v-col>
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 mb-4 mt-3">
                                                                                            <p class="mb-0 informacionCancelacion ">Información de cancelación:</p>
                                                                                            <div class="my-5">
                                                                                                No hay información para mostrar
                                                                                            </div>
                                                                                        </v-col>
                                                                                    </template>
                                                                                </v-col>
                                                                            </v-row>                           
                                                                        </v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </template>
                                                    <v-list-item
                                                        @click="descargar(item)"
                                                        v-if="item.url_archivo"
                                                    >
                                                        <v-list-item-title>Descargar archivo</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </td>
                                </template>
                                <template v-if="rol == 'empleado'">
                                    <td>
                                        <div class="tblOpciones">
                                            <v-tooltip bottom v-if="item.url_archivo">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        color="#FFFFFF"
                                                        fab
                                                        class="btn-download-cloud2 mr-3"
                                                        elevation="0"
                                                        width="42"
                                                        height="42"
                                                        @click="item.url_archivo ? descargar(item): ''"
                                                    >
                                                        <v-icon color="#96999A" v-text="'$get_app_outline'"></v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Descargar archivo</span>
                                            </v-tooltip>

                                            <v-tooltip bottom v-if="!item.url_archivo">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="botonHover" icon fab dark small>
                                                        <v-icon color="#cacaca" v-text="'$get_app_outline'"></v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>No hay archivo para descargar</span>
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-layout row justify-center>
                <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>

                    <perfect-scrollbar style="background:#FFF">
                        <v-card elevation="0">
                            <ValidationObserver ref="form" v-slot="{ invalid }" :key="keyDialog">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ titulo_modal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="loadingModal" v-show="!finaliza_carga">
                                        <div id="loading">
                                            <v-progress-circular
                                                indeterminate
                                                :size="100"
                                                :width="7"
                                                color="blue"
                                            ></v-progress-circular>
                                        </div>
                                    </div>
                                    <v-container v-show="finaliza_carga" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <template v-if="rol == 'root'">
                                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            required 
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root' || rol == 'admin'">
                                                <v-col cols="12" xs="12" :sm="(rol == 'root') ? '6':'12'" :md="(rol == 'root') ? '6':'12'" :lg="(rol == 'root') ? '6':'12'" :xl="(rol == 'root') ? '6':'12'" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Empresa"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            required 
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="sucursales"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Sucursal"
                                                            persistent-hint
                                                            v-model="sucursal_value"
                                                            required 
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'">
                                                <v-col 
                                                    cols="12"
                                                    xs="12" 
                                                    :sm="(rol == 'root' || rol == 'admin' || rol == 'admin-empresa') ? '6':'12'" 
                                                    :md="(rol == 'root' || rol == 'admin' || rol == 'admin-empresa') ? '6':'12'" 
                                                    :lg="(rol == 'root' || rol == 'admin' || rol == 'admin-empresa') ? '6':'12'" 
                                                    :xl="(rol == 'root' || rol == 'admin' || rol == 'admin-empresa') ? '6':'12'" 
                                                    class="py-0"
                                                >
                                                    <ValidationProvider v-slot="{ errors }" name="Departamento" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="departamentos"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Departamento"
                                                            persistent-hint
                                                            v-model="departamento_value"
                                                            required 
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Tipo incidencia" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="tiposIncidencias"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Tipo incidencia"
                                                        persistent-hint
                                                        v-model="incidencia.tipo_id"
                                                        :error-messages="errors" 
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                    <v-textarea
                                                        autofocus
                                                        v-model="incidencia.descripcion"
                                                        label="Descripción"
                                                        outlined
                                                        rows="2"
                                                        :error-messages="errors" 
                                                        required
                                                    ></v-textarea>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="incidencia.fecha_inicio"
                                                    label="Fecha inicial"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaInicio"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="incidencia.fecha_final"
                                                    label="Fecha final"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaFinal"
                                                />
                                            </v-col>
                        
                                            
                                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" v-if="rol != 'empleado'" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Estatus" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="estatus"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Estatus"
                                                        persistent-hint
                                                        v-model="incidencia.estatus_id"
                                                        required 
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                <v-text-field
                                                    outlined
                                                    readonly
                                                    class="pt-0 mt-0"
                                                    label="Subir archivo"
                                                    @click="pickFile"
                                                    v-model="nombre_archivo"
                                                >
                                                    <template slot="append">
                                                        <v-icon class="tamIcono">attach_file</v-icon>
                                                    </template>
                                                </v-text-field>
                                                <input
                                                    type="file"
                                                    style="display: none"
                                                    ref="image"
                                                    id="archivo"
                                                    @change="onFilePicked"
                                                />
                                            </v-col>
                                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal' || rol == 'admin-depto'">
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empleado" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empleados"
                                                            item-text="nombre_completo"
                                                            item-value="id"
                                                            label="Empleado"
                                                            persistent-hint
                                                            v-model="incidencia.empleado_id"
                                                            required 
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions v-show="finaliza_carga" class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()"
                                        :disabled="loading_guardar"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()"
                                        :loading="loading_guardar"
                                        v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                    >
                                        Guardar
                                    </v-btn>
                                                
                                </v-card-actions>

                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </v-layout>
        </template>

        <template>
            <v-dialog v-model="dialogReportes" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalReportes"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">{{ tituloReportes }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingReportes">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingReportes" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Cliente"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                required
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Empresa"
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="empresa_value"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            rules="required"
                                            outlined
                                            v-model="advancedSearch.fecha_inicio"
                                            label="Fecha inicial"
                                            placeholder="dd/mm/aaaa"
                                            ref="SearchFechaInicio"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            rules="required"
                                            outlined
                                            v-model="advancedSearch.fecha_final"
                                            label="Fecha final"
                                            placeholder="dd/mm/aaaa"
                                            ref="SearchFechaFinal"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <v-container class="ma-0 pa-0" fluid>
                                            <h4 class="tituloRadio">Tipo de reporte</h4>
                                            <v-radio-group class="ma-0 pa-0" v-model="tipoReporte">
                                                <v-radio
                                                    value="pdf"
                                                >
                                                    <template v-slot:label>
                                                        <div>Archivo pdf <v-icon color="red">mdi-file-pdf</v-icon></div>
                                                    </template> 
                                                </v-radio>
                                                <v-radio
                                                    value="xlsx"
                                                >
                                                    <template v-slot:label>
                                                        <div>Archivo excel <v-icon color="green">mdi-file-excel</v-icon></div>
                                                    </template> 
                                                </v-radio>
                                            </v-radio-group>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                            <v-btn class="btnGuardar" @click="exportarReporte(tipoReporte)" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>

        <template>
            <v-dialog v-model="dialogCancelar" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="display: none;" class="botonModal"></v-btn>
                </template>
                <ValidationObserver ref="formCancelar" v-slot="{ invalid }">
                    <v-card>
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho d-flex justify-center">
                            <div class="header-cancelar">
                                <img src="/static/icon/alert_warning.png" alt="Imagen cancelar" class="mb-5">
                                <h2 class="header-cancelar-h2 mb-4">CANCELAR INCIDENCIA</h2>
                                <h2 class="header-cancelar-h3 mb-5 text-center">Para cancelar una incidencia, se requiere describir el motivo de la cancelación.</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            
                            <v-container grid-list-md id="contenedor">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pb-0 pt-5">
                                        <ValidationProvider v-slot="{ errors }" name="Motivo" rules="required">
                                            <v-textarea
                                                outlined
                                                label="Motivo"
                                                :rows="2"
                                                v-model="cancelarIncidencia.motivo"
                                                :error-messages="errors"
                                            ></v-textarea>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="12" md="12" class="mt-0 pt-0">
                                        <div class="alert-div mt-0">
                                            <div>&nbsp;</div>
                                            <div class="d-flex">
                                                <div class="alert-sub pr-2">Cuando se cancela una incidencia, no será posible cambiar el estatus de la misma, por lo que es necesario crear una nueva.</div>
                                            </div>
                                            <div>&nbsp;</div>
                                        </div>
                                    </v-col>
                                    
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button 
                                class="btnCerrar" 
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                                >Cerrar
                            </button>
                            <v-btn
                                class="btnCancelar ml-4"
                                @click="cancelar()" 
                                :loading="isSaving"
                                :disabled="invalid" 
                            >
                                <span class="mx-3">Cancelar incidencia</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Datatable from "@/components/datatable/Datatable.vue";
import Notify from "@/plugins/notify";
import incidenciaApi from "../api/incidencias";
import tiposIncidenciasApi from "../api/tiposIncidencias";
import estatusIncidenciaApi from "../api/estatusIncidencias";
import empleadosApi from "../api/empleados";
import clientesApi from "../api/clientes";
import empresaApi from "../api/empresas";
import sucursalApi from "../api/sucursales";
import departamentoApi from "../api/departamentos";
import urlImg from "../plugins/Urlimagen";
import PerfectScrollbar from "perfect-scrollbar";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

import moment from "moment";
moment.locale("es");

export default {
    components: {
        "data-table": Datatable,
        VuetifyDatePicker,
    },
    data() {
        return {
            url: "incidencias/find",
            columns: [
                {
                    label: "Empleado",
                    name: "empleado",
                    align: "left",
                    filterable: true,
                },
                {
                    label: "Incidencia",
                    name: "tipo",
                    align: "left",
                    filterable: true,
                },
                {
                    label: "Fecha inicio",
                    name: "fecha_inicio",
                    align: "left",
                    filterable: true,
                },
                {
                    label: "Fecha final",
                    name: "fecha_final",
                    align: "left",
                    filterable: true,
                },
                {
                    label: "Estatus",
                    name: "estatus",
                    align: "center",
                    filterable: true,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    align: "center",
                    filterable: false,
                },
            ],
            filters: {
                rol_name        : null,
                usuario_id      : null,
                paginate        : true,
                include_padres  : true,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                empleado_id     : null,
                order_by        : 'fecha_final',
            },
            advancedSearch: {
                rol_name    : null,
                usuario_id  : null,
                tipo_id     : null,
                estatus_id  : null,
                fecha_inicio: null,
                fecha_final : null,
                empleado_id : null,
                cliente_id  : null,
                empresa_id  : null,
                sucursal_id : null,
                departamento: null,
            },
            months: [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
            ],
            loading_guardar : false,
            loading         : true,
            dialog          : false,
            menu_inicio     : false,
            menu_final      : false,
            fecha_final     : null,
            fecha_inicio    : null,
            datosLogin      : "",
            calendar_inicio : false,
            calendar_fin    : false,
            incidencia      : {
                id          : null,
                descripcion : "",
                tipo_id     : null,
                fecha_inicio: "",
                fecha_final : "",
                empleado_id : "",
                cliente_id  : null,
                empresa_id  : null,
                sucursal_id : null,
                departamento_id: null,
                estatus_id  : "",
                url_archivo : "",
            },
            tiposIncidencias: [],
            valorIncidencia : null,
            rol             : "",
            accion          : "",
            titulo_modal    : "",
            finaliza_carga  : false,
            cliente_value   : null,
            empresa_value   : null,
            sucursal_value  : null,
            departamento_value: null,
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            departamentos   : [],
            empleados       : [],
            estatus         : [],
            conteo_parametros: 0,
            incidencias     : [],
            nombre_archivo  : "",
            archivo         : 0,
            empleado_correo : null,
            url_archivo     : null,
            parametros      : null,
            urlAvatar       : null,
            btnDoc          :false,
            imagen          : "",
            sistema         : null,
            numRegistros    : 0,
            overlay         : false,
            fraseOverlay    :'Descargando',
            isLoadingReportes: false,
            dialogReportes  : false,
            isSaving        : false,
            tituloReportes  : "Descagar reporte",
            tipoReporte     : "pdf",
            dialogStatus    : false,
            keyDialog       : 0,
            dialogCancelar  : false,
            cancelarIncidencia: {
                id: null,
                motivo: null,
            },
            estatusTabla    : [],

            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        };
    },
    watch: {
        cliente_value: function(val) {
            if(val != null){
                this.cambiaCliente(val);
            }
        },
        empresa_value: function(val) {
            if(val != null){
                this.cambiaEmpresa(val);
                this.getTiposIncidencias(val);
            }
        },
        sucursal_value: function(val) {
            if(val != null){
                this.cambiaSucursal(val);
            }
        },
        departamento_value: function(val) {
            if(val != null){
                this.cambiaDepartamento(val);
            }
        },
    },
    methods: {
        listar(parametros = null) {
            let self = this;
            this.resetValue();
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.urlAvatar = urlImg.getURL();
            this.filters.rol_name = this.datosLogin.rol.name;
            this.filters.usuario_id = this.datosLogin.id;

            if (this.rol == "admin") {
                this.incidencia.cliente_id = this.filters.cliente_id = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.incidencia.empresa_id = this.filters.empresa_id = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.incidencia.sucursal_id = this.filters.sucursal_id = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                this.incidencia.departamento_id = this.filters.departamento_id = this.datosLogin.departamento_id;
            } else if (this.rol == "empleado") {
                this.columns = [
                    { label: "Incidencia", name: "incidencia", align: "left" },
                    { label: "Fecha inicio", name: "fecha_inicio_ok", align: "left" },
                    { label: "Fecha final", name: "fecha_final_ok", align: "left" },
                    { label: "Descripcion", name: "descripcion", align: "left" },
                    { label: "Estatus", name: "estatus", align: "center" },
                    {
                        label: "Opciones",
                        name: "opciones",
                        align: "center",
                        filterable: false,
                    },
                ];
                this.filters.empleado_id = this.$session.get("empleado").id;
            }
        },
        setFilters() {
            let tipo_id         = this.advancedSearch.tipo_id;
            let estatus_id      = this.advancedSearch.estatus_id;
            let fecha_inicio    = this.advancedSearch.fecha_inicio;
            let fecha_final     = this.advancedSearch.fecha_final;
            let empleado_id     = null;
            let cliente         = this.cliente_value;
            let empresa         = this.empresa_value;
            let sucursal        = this.sucursal_value;
            let departamento    = this.incidencia.departamento_id;
            let cliente_id;
            let empresa_id;
            let sucursal_id;
            let departamento_id;
            let activo          = null;
            let search_estatus_id = null;

            if (this.rol == "empleado") {
                empleado_id = this.$session.get("empleado").id;
            } else {
                empleado_id = this.advancedSearch.empleado_id;
            }

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                departamento = this.datosLogin.departamento_id;
            }

            if(estatus_id == null){
                activo = null;
                search_estatus_id = null;
            }
            else if(estatus_id == 99999){
                activo = false;
                search_estatus_id = null;
            }
            else{
                activo = true;
                search_estatus_id = estatus_id;
            }

            let filterParams = {
                activo          : activo,
                tipo_id         : tipo_id,
                estatus_id      : search_estatus_id,
                min_fecha_inicio: fecha_inicio,
                max_fecha_final : fecha_final,
                empleado_id     : empleado_id,

                cliente_id      : cliente,
                empresa_id      : empresa,
                sucursal_id     : sucursal,
                departamento_id : departamento,
                rol_name        : this.datosLogin.rol.name,
                usuario_id      : this.datosLogin.id,
            };

            let defaultParams = {
                paginate: true,
                include_padres: true,
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }

            this.filters = defaultParams;

            this.advancedSearch.tipo_id = null;
            this.advancedSearch.estatus_id = null;
            this.advancedSearch.fecha_inicio = null;
            this.advancedSearch.fecha_final = null;
            this.advancedSearch.empleado_id = null;

            this.cliente_value = null;
            this.empresa_value = null;
            this.sucursal_value = null;
            this.incidencia.departamento_id = null;
        },
        async loadModalData() {
            await this.resetValue();
            this.accion = 'search'
            let cliente = null;
            let empresa = null;
            let sucursal = null;
            let depto = null;
            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
                cliente = this.datosLogin.cliente_id;
            }

            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
                empresa = this.datosLogin.empresa_id;
            }

            if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cambiaSucursal(this.datosLogin.sucursal_id, this.accion);
                sucursal = this.datosLogin.sucursal_id;
            }

            if(this.rol == "admin-depto"){
                this.getTiposIncidencias(this.datosLogin.empresa_id);
            }

            if(this.rol == "empleado"){
                this.getTiposIncidencias(this.datosLogin.empresa_id);
            }


            
            this.getEstatusIncidencia();

            let param = {
                activo          : true,
                paginate        : false,
                cliente_id      : cliente,
                empresa_id      : empresa,
                sucursal_id     : sucursal,
                departamento_id : depto,
                rol_name        : this.datosLogin.rol.name,
                usuario_id      : this.datosLogin.id,
            };
            if (this.rol != "empleado") {
                empleadosApi.getEmpleados(param).then((response) => {
                    let formateado = response.data;
                    formateado.forEach((element) => {
                        let nombre = element["nombre"];
                        let apellido_paterno = element["apellido_paterno"];
                        element["nombre_completo"] = nombre + " " + apellido_paterno;
                    });
                    this.empleados = formateado;
                })
                .catch((err) => {
                    this.loading_guardar = false;
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los empleados.");
                });
            }

            this.finaliza_carga = true;
            //setTimeout(function(){ document.getElementById("btnBusquedaAvanzada").click(); }, 100);
        },
        async abrirModal(accion, item = null) {
            this.keyDialog = this.keyDialog + 1;
            await this.resetValue();
            this.accion = accion;
            this.finaliza_carga = false;
            await this.getEstatusIncidencia();
            if (accion == "add") {
                this.titulo_modal = "Registrar incidencia";
                this.imagen = "/static/modal/incidenciaCreate.svg";
                if (this.rol == "root") {
                    this.getClientes();
                }
                if (this.rol == "admin") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
                }
                if (this.rol == "admin-empresa") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
                }
                if (this.rol == "admin-sucursal") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                    this.cambiaSucursal(this.datosLogin.sucursal_id, this.accion);
                }
                if(this.rol == 'admin-depto'){
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                    this.getTiposIncidencias(this.datosLogin.empresa_id);
                    this.getEmpleados();
                }
                if (this.rol == "empleado") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                }
                this.finaliza_carga = true;
            } else if (accion == "update") {
                this.titulo_modal               = "Actualizar incidencia";
                this.imagen                     = "/static/modal/incidenciaUpdate.svg";
                this.incidencia.id              = item.id;
                this.incidencia.descripcion     = item.descripcion;
                this.incidencia.fecha_inicio    = item.fecha_inicio;
                this.incidencia.fecha_final     = item.fecha_final;
                this.incidencia.tipo_id         = item.tipo_id;
                this.incidencia.estatus_id      = item.estatus_id;
                this.cliente_value              = item.cliente_id;
                this.empresa_value              = item.empresa_id;
                this.sucursal_value             = item.sucursal_id;
                this.departamento_value         = item.departamento_id;
                this.incidencia.empleado_id     = item.empleado_id;
                this.incidencia.url_archivo     = item.url_archivo;
                this.url_archivo                = item.url_archivo;
                if (this.rol == "root") {
                    this.getClientes();
                }
                setTimeout(()=>this.$refs.FechaInicio.setDate(item.fecha_inicio),200);
                setTimeout(()=>this.$refs.FechaFinal.setDate(item.fecha_final),200);

                if (item.cliente != undefined) {
                    this.incidencia.cliente_id = item.cliente_id;
                }

                if (item.empresa != undefined) {
                    this.incidencia.empresa_id = item.empresa_id;
                }

                if (item.sucursal != undefined) {
                    this.incidencia.sucursal_id = item.sucursal_id;
                }

                if (item.departamento != undefined) {
                    this.incidencia.departamento_id = item.departamento_id;
                }

                if (item.empleado != undefined) {
                    this.incidencia.empleado_id = item.empleado_id;
                }

            }
            setTimeout(function() {
                document.getElementById("btnModal").click();
            }, 100);
        },
        guardar() {
            let self = this;
            var f_inicio = new Date(this.incidencia.fecha_inicio);
            var f_final = new Date(this.incidencia.fecha_final);

            self.valorIncidencia = null;
            this.tiposIncidencias.forEach(function(element) {
                if (element.id == self.incidencia.tipo_id)
                self.valorIncidencia = element.valor;
            });
            if (f_inicio > f_final) {
                Notify.Info("Rango de fechas incorrectas", "La fecha de inicio es mayor que la fecha final");
            } else if (
                (self.url_archivo == null || self.url_archivo == "" || self.url_archivo == undefined) 
                && self.valorIncidencia == "tipo_incidencia_incapacidad" 
                && (this.nombre_archivo == "" || this.nombre_archivo == null || this.nombre_archivo == undefined)
            ) {
                Notify.Info("Se requiere documento", "Para guardar la incidencia se requiere un documento");
            } else {
                this.$refs.form.validate().then(result => {
                    if (result) {
                        this.loading_guardar = true;
                        this.incidencia.cliente_id = this.cliente_value;
                        this.incidencia.empresa_id = this.empresa_value;
                        this.incidencia.sucursal_id = this.sucursal_value;
                        this.incidencia.departamento_id = this.departamento_value;

                        if (this.accion === "add") {
                            if (this.rol == "empleado") {
                                this.incidencia.estatus_id = 1;
                                this.incidencia.empleado_id = this.$session.get("empleado").id;
                                this.incidencia.departamento_id = this.datosLogin.departamento_id;
                            }
                            
                            let formData = new FormData();

                            formData.append("empleado_id",this.incidencia.empleado_id);
                            formData.append("descripcion",this.incidencia.descripcion);
                            formData.append("tipo_id",this.incidencia.tipo_id);
                            formData.append("fecha_inicio",this.incidencia.fecha_inicio);
                            formData.append("fecha_final",this.incidencia.fecha_final);
                            formData.append("estatus_id",this.incidencia.estatus_id);
                            formData.append("url",window.location.origin)

                            if(this.archivo != 0){
                                formData.append("archivo", this.archivo);
                            }

                            incidenciaApi.addIncidencia(formData).then((response) => {
                                self.listar();
                                self.respuesta_ok("Se ha realizado la operación correctamente.");
                                this.$refs.tableIncidencias.getData();
                                this.$emit("setNotifications", response.notificaciones);
                            })
                            .catch((err) => {
                                self.respuesta_error(err);
                            });
                           
                        } else if (this.accion === "update") {
                            let formData = new FormData();
                            formData.append("id",this.incidencia.id);
                            formData.append("empleado_id",this.incidencia.empleado_id);
                            formData.append("descripcion",this.incidencia.descripcion);
                            formData.append("tipo_id",this.incidencia.tipo_id);
                            formData.append("fecha_inicio",this.incidencia.fecha_inicio);
                            formData.append("fecha_final",this.incidencia.fecha_final);
                            formData.append("estatus_id",this.incidencia.estatus_id);
                            formData.append("url",window.location.origin)
                            
                            if(this.archivo != 0){
                                formData.append("archivo", this.archivo);
                            }

                            incidenciaApi.updateIncidencia(formData).then((response) => {
                                self.listar();
                                self.respuesta_ok("Se ha realizado la operacion correctamente.");
                                this.$refs.tableIncidencias.getData();

                                if (response.notificaciones.length > 0)
                                this.$emit("setNotifications", response.notificaciones);
                            })
                            .catch((err) => {
                                self.respuesta_error(err);
                            });
                        }
                    }
                });
            }
        },
        eliminar(id, incidencia) {
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas cancelar la incidencia?",
                "Cancelar",
                () => {
                    incidenciaApi.deleteIncidencia(id).then((response) => {
                            this.$refs.tableIncidencias.getData();
                            this.respuesta_ok("La incidencia ha sido cancelada satisfactoriamente.");
                    })
                    .catch((err) => {
                            console.log(err);
                            Notify.ErrorToast(err.response.data.error);
                    });
                }
            );
        },
        async getTiposIncidencias(empresa = null) {
            let param           = {
                activo          : true,
                paginate        : false,
                empresa_id      : empresa,
            };
            await tiposIncidenciasApi.getTiposIncidencias(param).then((response) => {
                this.tiposIncidencias = response.data;
            })
            .catch((err) => {
                this.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los tipos de incidencias.");
            });
        },
        async getEstatusIncidencia() {
            let param = { activo: true, paginate: false };
            await estatusIncidenciaApi.getEstatusIncidencias(param).then((response) => {
                if(this.accion == 'search'){
                    let objCancelacion = {
                        "id": 99999,
                        "nombre": "Cancelado",
                        "valor": "cancelado",
                        "activo": true
                    }
                    this.estatus = [...response.data, objCancelacion]
                }
                else {
                    this.estatus = response.data;
                }

            })
            .catch((err) => {
                this.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los estatus de las incidencias.");
            });
        },
        async getEstatusIncidenciaTabla() {
            let param = { activo: true, paginate: false };
            await estatusIncidenciaApi.getEstatusIncidencias(param).then((response) => {
                this.estatusTabla = response.data;
            })
            .catch((err) => {
                this.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los estatus de las incidencias.");
            });
        },
        async getEmpleados() {
            let param = {
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
                activo: true,
                paginate: false,
            };
            await empleadosApi.getEmpleados(param).then((response) => {
                this.empleados = response.data;
            })
            .catch((err) => {
                this.respuesta_error(err);
            });
        },
        async getClientes(clientes = null) {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        async cambiaCliente(cliente, accion = null) {
            let param = { activo: true, paginate: false, cliente_id: cliente };
            await empresaApi.getEmpresas(param).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        async cambiaEmpresa(empresa, accion = null) {
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        async cambiaSucursal(sucursal, accion = null) {
            let parametros = { activo: true, paginate: false, sucursal_id: sucursal };
            await departamentoApi.getDepartamentos(parametros).then((response) => {
                this.departamentos = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
            });
        },
        async cambiaDepartamento(departamento, accion = null) {
            let param = {
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
                activo: true,
                paginate: false,
                departamento_id: departamento,
            };
            await empleadosApi.getEmpleados(param).then((response) => {
                this.finaliza_carga = true;
                let formateado = response.data;
                formateado.forEach((element) => {
                    let nombre = element["nombre"];
                    let apellido_paterno = element["apellido_paterno"];
                    element["nombre_completo"] = nombre + " " + apellido_paterno;
                });
                this.empleados = formateado;
            })
            .catch((err) => {
                this.finaliza_carga = true;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los empleados.");
            });
        },
        cerrarModal() {
            this.dialog = false;
            this.dialogReportes = false;
            this.dialogStatus = false;
            this.dialogCancelar = false;
            this.resetValue();
        },
        resetValue() {
            this.incidencia.id              = null;
            this.incidencia.descripcion     = ""; 
            this.incidencia.tipo_id         = null;
            this.incidencia.estatus_id      = "";
            this.incidencia.fecha_inicio    = "";
            this.incidencia.fecha_final     = "";
            this.incidencia.empleado_id     = "";
            this.incidencia.cliente_id      = null;
            this.incidencia.empresa_id      = null;
            this.incidencia.sucursal_id     = null;
            this.incidencia.departamento_id = null;
            this.incidencia.url_archivo     = "";
            this.clientes                   = [];
            this.empresas                   = [];
            this.sucursales                 = [];
            this.departamentos              = [];
            this.empleados                  = [];
            this.tiposIncidencias           = [];
            this.estatus                    = [];
            this.cliente_value              = null;
            this.empresa_value              = null;
            this.sucursal_value             = null;
            this.departamento_value         = null;
            this.loading_guardar            = false;
            this.conteo_parametros          = 0;
            this.archivo                    = 0;
            this.nombre_archivo             = "";
            this.menu_final                 = false;
            this.menu_inicio                = false;
            this.fecha_final                = null;
            this.fecha_inicio               = null;
            this.url_archivo                = null;
            $("#archivo").val("");
            this.accion                     = null;
            this.isSaving                   = false;

            this.cancelarIncidencia = {
                id: null,
                motivo: null,
            };

            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }

            if(this.$refs.SearchFechaInicio != undefined || this.$refs.SearchFechaInicio != null){
                this.$refs.SearchFechaInicio.resetDate();
            }
            if(this.$refs.SearchFechaFinal != undefined || this.$refs.SearchFechaFinal != null){
                this.$refs.SearchFechaFinal.resetDate();
            }

            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                } 
                if(this.$refs.formCancelar != undefined || this.$refs.formCancelar != null){
                    this.$refs.formCancelar.reset();
                } 
            });
        },
        respuesta_error(err) {
            this.loading_guardar = false;
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },
        respuesta_ok(msj = "") {
            this.listar();
            this.cerrarModal();
            this.resetValue();
            this.dialog = false;
            Notify.Success("Operación exitosa", msj);
        },
        valida_empleado() {
            let self = this;
            let empleado = this.$session.get("empleado");
            if (empleado != undefined) {
                this.listar();
            } else {
                setTimeout(function() {
                    self.valida_empleado();
                }, 300);
            }
        },
        pickFile() {
            this.$refs.image.click();
        },
        onFilePicked(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.nombre_archivo = files[0].name;
                if (this.nombre_archivo.lastIndexOf(".") <= 0) {
                    return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                    this.archivo = files[0];
                });
            } else {
                this.nombre_archivo = "";
                this.archivo = null;
            }
        },
        async descargar(item = null) {
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "incidencias/download";
            var nombre = item.url_archivo.split("/");
            let parametros = { url_archivo: item.url_archivo };
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    FileDownload(response.data, nombre[nombre.length - 1]);
                });
            } catch (error) {
                console.log(error);
            }
        },
        async exportar(tipo_doc = null) {
            if(this.numRegistros == 0){
                Notify.ErrorToast("No hay registros para realizar la descarga del reporte.");
                return;
            }
            this.overlay = true;
            this.fraseOverlay = "Descargando";
            let self = this;
            let url = "reportes/incidencias";
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre;
            if(tipo_doc == 'xlsx'){
                nombre = "incidencias_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".xlsx";
            }
            else{
                nombre = "incidencias_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".pdf";
            }
            this.filters.tipo = tipo_doc;
            const object1 = this.filters;
            try {
                await Vue.axios({
                    method: "GET",
                    responseType: "blob",
                    url: url,
                    params: object1,
                }).then((response, status, xhr) => {
                    self.overlay = false;
                    FileDownload(response.data, nombre);
                });
            } catch (e) {
                self.overlay = false;
                var message = null;
            }
        },
        getColorTextoStatus(statusIncidencia) {
            if (statusIncidencia == "estatus_aprobado") return "estatus_aprobado";
            else if (statusIncidencia == "estatus_rechazado") return "estatus_rechazado";
            else if (statusIncidencia == "estatus_pendiente") return "estatus_pendiente";
            else if (statusIncidencia == "estatus_revision") return "estatus_revision";
            else return '';
        },
        init() {
            const elementScroll = document.querySelector("#scroll");
            if (elementScroll) {
                const ps = new PerfectScrollbar(elementScroll, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollY: true,
                });
            }
            $(".ps__thumb-x").css("position", "fixed");
            $("tr th:nth-child(6)").css("width", "17%");
            $("tr th:nth-child(2)").css("width", "15%");
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        datosTabla(datos){
            this.numRegistros = datos.data.length;
        },
        abrirModalReportes(){
            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            setTimeout(()=>{
                document.getElementById("btnModalReportes").click();
            }, 100);
        },
        async exportarReporte(tipo_doc = null) {
            this.isSaving = true;
            let self = this;
            let url = "reportes/incidencias";
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre;
            if(tipo_doc == 'xlsx'){
                nombre = "incidencias_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".xlsx";
            }
            else{
                nombre = "incidencias_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".pdf";
            }
            let filtros = {
                items_to_show: 200,
                tipo:tipo_doc,
                activo: true,
                include_padres: true,
                rol_name: "admin-empresa",
                usuario_id: this.datosLogin.id,
                cliente_id: this.cliente_value,
                empresa_id: this.empresa_value,
                min_fecha_inicio: this.advancedSearch.fecha_inicio,
                max_fecha_final : this.advancedSearch.fecha_final,            
            }
            const object1 = filtros;
            try {
                await Vue.axios({
                    method: "GET",
                    responseType: "blob",
                    url: url,
                    params: object1,
                }).then((response, status, xhr) => {
                    self.isSaving = false;
                    FileDownload(response.data, nombre);
                    self.cerrarModal();
                })
                .catch(async (error)=>{
                    self.isSaving = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                    
                });
            } catch (e) {
                self.isSaving = false;
                Notify.ErrorToast("Se produjo un error al realizar la petición.");
            }
        },

        

        async actualizarEstatus(item, estatus){

            let incidencia = {
                id              : item.id,
                descripcion     : item.descripcion,
                fecha_inicio    : item.fecha_inicio,
                fecha_final     : item.fecha_final,
                tipo_id         : item.tipo_id,
                estatus_id      : estatus.id,
                cliente_id      : item.cliente_id,
                empresa_id      : item.empresa_id,
                sucursal_id     : item.sucursal_id,
                departamento_id : item.departamento_id,
                empleado_id     : item.empleado_id,
                url_archivo     : item.url_archivo,
                url             : window.location.origin
            }

            this.overlay        = true;
            this.fraseOverlay   = "Actualizando";

            incidenciaApi.updateIncidencia(incidencia).then((response) => {
                this.listar();
                this.respuesta_ok( "Se ha realizado la actualización correctamente.");
                this.$refs.tableIncidencias.getData();
                this.overlay        = false;
                if (response.notificaciones && response.notificaciones.length > 0){
                    this.$emit("setNotifications", response.notificaciones);
                }
            })
            .catch((err) => {
                this.overlay        = false;
                this.respuesta_error(err);
                console.log(err)
            });

            
            this.dialogStatus = true;
        },
        abrirModalCancelar(item){
            this.dialogCancelar = true;
            this.cancelarIncidencia.id = item.id;

        },
        cancelar(data){
            Notify.Alert2(
                "ADVERTENCIA",
                "¿Estás seguro que deseas cancelar la incidencia?",
                "Cancelar incidencia",
                "Cerrar",
                () => {
                    this.isSaving = true;
                    let hoy = moment().format("YYYY-MM-DD HH:mm:ss");
                    this.cancelarIncidencia.fecha_pedido = hoy

                    incidenciaApi.cancelar(this.cancelarIncidencia).then(response => {
                        Notify.Success("Incidencia cancelada", "La incidencia ha sido cancelada satisfactoriamente.");
                        this.cerrarModal();
                        this.$refs.tableIncidencias.getData();
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("incidencias.store")
            this.permisos.update = permisosStorage.includes("incidencias.update")
            this.permisos.delete = permisosStorage.includes("incidencias.delete")
        },
    },
    mounted() {
        //this.init();
    },
    created() {
        this.permisosVista();
        let self = this;
        let session = this.$session.get("usuario").rol.name;
        this.sistema = this.$session.get('sistema');
        if (session != "empleado") {
            self.listar();
            this.getEstatusIncidenciaTabla();
        } else {
            self.valida_empleado();
        }
    },
};
</script>

<style scoped>
    .vChip {
        width: 120px;
        justify-content: center;
        font-size: 12px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
    }
    .vChip .v-chip .v-chip__content {
        font-family: Montserrat !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        letter-spacing: -0.34px !important;
        line-height: 17px !important;
        width: 90px !important;
        color: #2c2b2b !important;
    }

    .v-chip .v-chip__content {
        font-family: Montserrat !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 15px !important;
        text-align: center !important;
        color: #2c2b2b !important;
    }
    .tipoLetra {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px;
        text-align: center;
        display: flex;
        align-items: center;
    }
    .v-chip-hover .v-chip .v-chip__content:hover {
        background-color: blue !important;
    }
    .tituloRadio{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #A0A4A8;
        padding-bottom: 10px;
    }

    .estatus_aprobado{
        color: #15D511;
    }   	
    .estatus_rechazado{
        color:#EE1616
    }	
    .estatus_pendiente{
        color:#FF9B05
    }	
    .estatus_revision{
        color:#F8690E
    }	
    .estatus_cancelado{
        color: #000000
    }

    .classEliminado{
        background: #ffdede;
    }
    .menuClassOpciones{
        border-radius: 10px!important;
    }

    .btnCancelar {
        height: 42px !important;
        border-radius: 30px;
        text-transform: none;
        background:#D41010 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }

    .header-cancelar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
    .header-cancelar-h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #4F4F4F;
    }
    .header-cancelar-h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }


    .menuClassAccionesVer{
        border-radius: 10px!important;
    }
    .menu-width{
        max-width: 500px;
        min-width: 450px;
        border-radius: 20px !important;
    }
    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .informacionCancelacion{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }
    .canceladoText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color:#D41010 !important;
    }

    .alert-div{
        border-left: 9px solid #D93025;
        padding-left: 10px;

        background: rgba(250, 230, 229, 0.6);
        border-radius: 5px
    }

    .alert-sub {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        color: #C5352B;
    }
</style>
